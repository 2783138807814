import React from "react";
import "./planirovka.css";
import { useTranslation } from "react-i18next";
const Planirvokaprduct = ({ code }) => {
  const { t } = useTranslation();
  return (
    code && (
      <section className="planirovka">
        <div className="container">
          <h2 className="planirovka__title">{t("main.mainpage.planirovka")}</h2>
          <div
            id="profitbase_front"
            style={{ height: "600px", width: "100%" }}
            dangerouslySetInnerHTML={{ __html: code }}
          ></div>
        </div>
      </section>
    )
  );
};

export default Planirvokaprduct;
