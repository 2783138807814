import React, { useEffect } from "react";

const BitrixForm = () => {
  useEffect(() => {
    (function (w, d, u) {
      var s = d.createElement("script");
      s.async = true;
      s.src = u + "?" + ((Date.now() / 180000) | 0);
      var h = d.getElementsByTagName("script")[0];
      h.parentNode.insertBefore(s, h);
    })(
      window,
      document,
      "https://bitrix.galamat.kz/upload/crm/form/loader_5_lctov3.js"
    );
  }, []);

  return (
    <div className="brix__callcard" id="wrap">
      <div
        dangerouslySetInnerHTML={{
          __html: `<script data-b24-form="inline/5/lctov3" data-skip-moving="true">
          (function(w,d,u){
              var s=d.createElement('script');
              s.async=true;
              s.src=u+'?'+(Date.now()/180000|0);
              var h=d.getElementsByTagName('script')[0];
              h.parentNode.insertBefore(s,h);
          })(window,document,'https://bitrix.galamat.kz/upload/crm/form/loader_5_lctov3.js');
      </script>`,
        }}
      ></div>
    </div>
  );
};

export default BitrixForm;
