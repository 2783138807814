import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1 style={{ fontSize: "72px", color: "#e74c3c" }}>404</h1>
      <h2 style={{ color: "#e74c3c" }}>{t("main.notfoud.title")}</h2>
      <p style={{ fontSize: "18px", color: "#777", marginBottom: "20px" }}>
        {t("main.notfoud.text")}
      </p>
      <button
        style={{
          border: "none",
          outline: "none",
          background: "#1d2c49",
          color: "#fff",
          borderRadius: "4px",
          cursor: "pointer",
          fontSize: "18px",
          padding: "10px 20px",
          margin: "0 10px",
        }}
        onClick={() => navigate("/")}
        className="notfoundbtn"
      >
        {t("main.notfoud.btn")}
      </button>
    </div>
  );
};

export default NotFound;
