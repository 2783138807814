import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/Images/svg/logo.svg";
import "./footer.css";
import { useTranslation } from "react-i18next";
import kazakhpdf from "../../assets/Pdf/kazak.pdf";
import ruspdf from "../../assets/Pdf/rus.pdf";
const Footer = () => {
  const { i18n } = useTranslation();

  const { t } = useTranslation();

  return (
    <footer>
      <section className="footer-section">
        <div className="container">
          <div className="footer__wrapper">
            <div className="footer__navbar">
              <Link to={"/"}>
                <img
                  data-b24-crm-button-icon="crmform"
                  data-b24-crm-button-pulse
                  src={logo}
                  alt="logo"
                />
              </Link>
              <nav className="footer__navlist">
                <Link className="foorter__link" to={"/product"}>
                  {t("main.header.proekti")}
                </Link>
                <Link className="foorter__link" to={"/commerce"}>
                  {t("main.header.komersiya")}
                </Link>
                <Link className="foorter__link" to={"/about"}>
                  {t("main.header.about")}
                </Link>
                <Link className="foorter__link" to={"/blog"}>
                  {t("main.header.blog")}
                </Link>
                <Link className="foorter__link" to={"/contact"}>
                  {t("main.header.contact")}
                </Link>
              </nav>
            </div>
            <div className="footer__bottom">
              <p className="footer__copyrigth">{t("main.header.copy")}</p>
              <a
                className="footer__copyrigth"
                target="_blanl"
                href={i18n.resolvedLanguage === "rus" ? ruspdf : kazakhpdf}
              >
                {t("main.header.rajdeniya")}
              </a>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
