import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import EarhGlobal from "../../assets/Images/svg/Eathglobal.svg";
import Location from "../../assets/Images/svg/Location.svg";
import logo from "../../assets/Images/svg/logo.svg";
import Menu from "../../assets/Images/png/Menu.png";
import Close from "../../assets/Images/svg/Close.svg";
import "./header.css";
import kazak from "../../assets/Images/svg/kazakhstan-flag-icon.svg";
import russian from "../../assets/Images/svg/russia-flag-icon.svg";
import { setI18n, useTranslation } from "react-i18next";
import http from "../../untils/axios";
import Loader from "../../components/Load/Load";
import { Context } from "../../context/Context";
import { useNavigate } from "react-router-dom";
const Header = () => {
  const navigate = useNavigate();
  const { cityname, setCityname } = useContext(Context);
  const [citys, setCitys] = useState([]);
  const { t, i18n } = useTranslation();
  const [scrolling, setScrolling] = useState(false);
  const [menu, setMenu] = useState(false);
  const [barlang, setBarlang] = useState(false);
  const [city, setCity] = useState(false);
  const [loading, setLoading] = useState(false);
  const changeLanguage = (lan) => {
    i18n.changeLanguage(lan);
    window.location.reload();
    localStorage.setItem("lan", lan);
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [data, setData] = useState({});
  const [time, setTime] = useState("");

  const getContact = () => {
    http
      .get("/api/v1/contact/", {})
      .then((res) => {
        setData(res?.data[0]);
        setTime(res?.data[0]?.call_center);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCity = (item) => {
    setLoading(true);
    if (i18n.resolvedLanguage === "rus") {
      setCityname(item?.title_ru);
    } else {
      setCityname(item?.title_kk);
    }
    setCity(!city);
    if (window.location.pathname.split("/")[1] === "innerblog") {
      navigate("/blog");
    }
    if (
      window.location.pathname.split("/")[1] === "endedproeckt" ||
      window.location.pathname.split("/")[1] === "productitem"
    ) {
      navigate("/product");
    }
    sessionStorage.setItem("cityru", item?.title_ru);
    sessionStorage.setItem("citykk", item?.title_kk);
    setMenu(false);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  useEffect(() => {
    getContact();
  }, []);

  const getCity = () => {
    http
      .get(`/${i18n.resolvedLanguage === "rus" ? "ru" : "kk"}/api/v1/cities/`)
      .then((res) => {
        setCitys(res?.data);
        if (
          !sessionStorage.getItem("cityru") &&
          !sessionStorage.getItem("citykk")
        ) {
          if (i18n.resolvedLanguage === "rus") {
            setCityname(res?.data[0]?.title_ru);
          } else {
            setCityname(res?.data[0]?.title_kk);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCity();
  }, []);
  console.log();
  return (
    <>
      {loading && <Loader />}
      <header className={scrolling ? "header header-active" : "header"}>
        <section className="header-section">
          <div className="container">
            <div className="heder__wrapper">
              <div className="heder__logo">
                <Link onClick={() => setMenu(false)} to={"/"}>
                  <img className="site-logo" src={logo} alt="logo" />
                </Link>
                <div className="heder__location">
                  <div
                    onClick={() => setCity(!city)}
                    className="header__location-wrapper"
                  >
                    <div className="header__location-icon">
                      <img width={20} src={Location} alt="location" />
                    </div>
                    <div className="header__location-text">
                      <p>{cityname ? cityname : ""}</p>
                    </div>
                    <div
                      className={
                        city
                          ? "language__bar language__baropened"
                          : "language__bar"
                      }
                    >
                      {citys.map((item, index) => (
                        <button
                          key={index}
                          onClick={() => handleCity(item)}
                          className={"header__city__text"}
                        >
                          <span>
                            {i18n.resolvedLanguage === "rus"
                              ? item?.title_ru
                              : item?.title_kk}
                          </span>
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <nav className="heder__nav">
                <ul className="heder__nav-list">
                  <li className="header__nan-listitem">
                    <NavLink className="header__navlink" to="/product">
                      {t("main.header.proekti")}
                    </NavLink>
                  </li>
                  <li className="header__nan-listitem">
                    <NavLink className="header__navlink" to="/commerce">
                      {t("main.header.komersiya")}
                    </NavLink>
                  </li>
                  <li className="header__nan-listitem">
                    <NavLink className="header__navlink" to="/about">
                      {t("main.header.about")}{" "}
                    </NavLink>
                  </li>
                  <li className="header__nan-listitem">
                    <NavLink className="header__navlink" to="/blog">
                      {t("main.header.blog")}{" "}
                    </NavLink>
                  </li>
                  <li className="header__nan-listitem">
                    <NavLink className="header__navlink" to="/contact">
                      {t("main.header.contact")}{" "}
                    </NavLink>
                  </li>
                </ul>
              </nav>
              <div className="header__contact">
                <div className="header__contact-item">
                  <div className="header__contact-text">
                    {time && (
                      <a href={`tel:${time}`}>{`${time?.slice(
                        0,
                        2
                      )}(${time?.slice(2, 6)})${time?.slice(
                        6,
                        time?.length
                      )}`}</a>
                    )}
                  </div>
                  <button
                    onClick={() => setBarlang(!barlang)}
                    className="header__language"
                  >
                    <span
                      className={i18n.resolvedLanguage === "kaz" && "active"}
                    >
                      KAZ
                    </span>
                    /
                    <span
                      className={i18n.resolvedLanguage === "rus" && "active"}
                    >
                      RUS
                    </span>
                    <img
                      width={14}
                      height={14}
                      src={EarhGlobal}
                      alt="earthglobal"
                    />
                    <div
                      className={
                        barlang
                          ? "language__bar language__baropened  "
                          : "language__bar"
                      }
                    >
                      <button
                        onClick={() => changeLanguage("rus")}
                        className={
                          i18n.resolvedLanguage === "rus"
                            ? "language__baritem active"
                            : "language__baritem"
                        }
                      >
                        {" "}
                        <img height={20} width={40} src={russian} alt="" /> RUS
                      </button>
                      <button
                        onClick={() => changeLanguage("kaz")}
                        className={
                          i18n.resolvedLanguage === "kaz"
                            ? "language__baritem active"
                            : "language__baritem"
                        }
                      >
                        {" "}
                        <img src={kazak} width={40} height={20} alt="" /> KAZ
                      </button>
                    </div>
                  </button>
                </div>
              </div>
              <div className="header__burger">
                {menu ? (
                  <img src={Close} alt="menu" onClick={() => setMenu(!menu)} />
                ) : (
                  <img src={Menu} alt="menu" onClick={() => setMenu(!menu)} />
                )}
              </div>
            </div>
            <div
              className={
                menu
                  ? "heder__menu header__menuopened"
                  : "heder__menu heder__menuclosed"
              }
            >
              <nav className="heder__mobile">
                <ul className="heder__navmobile-list">
                  <li
                    onClick={() => setMenu(false)}
                    className="header__nan-listitem"
                  >
                    <NavLink className="header__navlink" to="/product">
                      {t("main.header.proekti")}
                    </NavLink>
                  </li>
                  <li
                    onClick={() => setMenu(false)}
                    className="header__nan-listitem"
                  >
                    <NavLink className="header__navlink" to="/commerce">
                      {t("main.header.komersiya")}
                    </NavLink>
                  </li>
                  <li
                    onClick={() => setMenu(false)}
                    className="header__nan-listitem"
                  >
                    <NavLink className="header__navlink" to="/about">
                      {t("main.header.about")}{" "}
                    </NavLink>
                  </li>
                  <li
                    onClick={() => setMenu(false)}
                    className="header__nan-listitem"
                  >
                    <NavLink className="header__navlink" to="/blog">
                      {t("main.header.blog")}{" "}
                    </NavLink>
                  </li>
                  <li
                    onClick={() => setMenu(false)}
                    className="header__nan-listitem"
                  >
                    <NavLink className="header__navlink" to="/contact">
                      {t("main.header.contact")}
                    </NavLink>
                  </li>
                </ul>
                <div className="heder__locationmobile">
                  <div
                    onClick={() => setCity(!city)}
                    className="header__location-wrapper"
                  >
                    <div className="header__location-icon">
                      <img width={20} src={Location} alt="location" />
                    </div>
                    <div className="header__location-text">
                      <p>{cityname ? cityname : ""}</p>
                    </div>
                    <div
                      className={
                        city
                          ? "language__bar language__baropened"
                          : "language__bar"
                      }
                    >
                      {citys.map((item, index) => (
                        <button
                          key={index}
                          onClick={() => handleCity(item)}
                          className={"header__city__text"}
                        >
                          <span>
                            {i18n.resolvedLanguage === "rus"
                              ? item?.title_ru
                              : item?.title_kk}
                          </span>
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="header__contact-text">
                  {time && (
                    <a href={`tel:${time}`}>{`${time?.slice(
                      0,
                      2
                    )}(${time?.slice(2, 6)})${time?.slice(
                      6,
                      time?.length
                    )}`}</a>
                  )}
                </div>
                <button
                  onClick={() => setBarlang(!barlang)}
                  className="header__language"
                >
                  <span className={i18n.resolvedLanguage === "kaz" && "active"}>
                    KAZ
                  </span>
                  /
                  <span className={i18n.resolvedLanguage === "ru" && "active"}>
                    RUS
                  </span>
                  <img
                    width={14}
                    height={14}
                    src={EarhGlobal}
                    alt="earthglobal"
                  />
                  <div
                    className={
                      barlang
                        ? "language__bar language__baropened  "
                        : "language__bar"
                    }
                  >
                    <button
                      onClick={() => changeLanguage("rus")}
                      className={
                        i18n.resolvedLanguage === "rus"
                          ? "language__baritem active"
                          : "language__baritem"
                      }
                    >
                      {" "}
                      <img height={20} width={40} src={russian} alt="" /> RUS
                    </button>
                    <button
                      onClick={() => changeLanguage("kaz")}
                      className={
                        i18n.resolvedLanguage === "kaz"
                          ? "language__baritem active"
                          : "language__baritem"
                      }
                    >
                      {" "}
                      <img src={kazak} width={40} height={20} alt="" /> KAZ
                    </button>
                  </div>
                </button>
              </nav>
            </div>
          </div>
        </section>
      </header>
    </>
  );
};

export default Header;
