import React, { useRef, useState } from "react";
import "./drobdown.css";
import dropdownimg from "../../../assets/Images/svg/dropdown.svg";
import "swiper/css";
import Drobdowncarusel from "./Drobdowncarusel";
import Btrixmodal from "../../Btrixmodal/Btrixmodal";
import { useTranslation } from "react-i18next";
const Drobdown = ({ data }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [dropdown3, setDropdown3] = useState(false);

  return (
    <section className="drobdown">
      <Btrixmodal setModal={setModal} modal={modal} />
      <div className="container">
        <h2 className="drobdown__maintitle"> {t("main.proektinner.vinfra")}</h2>
        <div className="dropdown__wrapper">
          <div className="dropdown__text">
            <h2 className="dropdown__title">{t("main.proektinner.holl")}</h2>
            <img
              width={38}
              onClick={() => setDropdown(!dropdown)}
              src={dropdownimg}
              alt="dropdownimg"
              className={
                dropdown
                  ? "dropdown__img dorpdown__imgrotate"
                  : "dropdown__img "
              }
            />
          </div>
          <div
            className={
              dropdown
                ? "dropdown__menu dropdown__menublock "
                : "dropdown__menu dropdown__menunone"
            }
          >
            <Drobdowncarusel
              setModal={setModal}
              text={data?.halls_description}
              images={data?.halls}
            />
          </div>
        </div>
        <div className="dropdown__wrapper">
          <div className="dropdown__text">
            <h2 className="dropdown__title">{t("main.proektinner.dvor")}</h2>
            <img
              width={38}
              onClick={() => setDropdown2(!dropdown2)}
              src={dropdownimg}
              alt="dropdownimg"
              className={
                dropdown2
                  ? "dropdown__img dorpdown__imgrotate"
                  : "dropdown__img "
              }
            />
          </div>
          <div
            className={
              dropdown2
                ? "dropdown__menu dropdown__menublock "
                : "dropdown__menu dropdown__menunone"
            }
          >
            <Drobdowncarusel
              setModal={setModal}
              text={data?.yards_description}
              images={data?.yards}
            />
          </div>
        </div>
        <div className="dropdown__wrapper">
          <div className="dropdown__text">
            <h2 className="dropdown__title">{t("main.proektinner.lift")}</h2>
            <img
              width={38}
              onClick={() => setDropdown3(!dropdown3)}
              src={dropdownimg}
              alt="dropdownimg"
              className={
                dropdown3
                  ? "dropdown__img dorpdown__imgrotate"
                  : "dropdown__img "
              }
            />
          </div>
          <div
            className={
              dropdown3
                ? "dropdown__menu dropdown__menublock "
                : "dropdown__menu dropdown__menunone"
            }
          >
            <Drobdowncarusel
              setModal={setModal}
              text={data?.lifts_description}
              images={data?.lifts}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Drobdown;
