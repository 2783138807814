import React from "react";
import "../../InnerProekt/AboutProekt/about.css";
import downloadUrl from "../../../untils/download";
import { useTranslation } from "react-i18next";
const Aboutended = ({ data }) => {
  const { t } = useTranslation();

  return (
    <section className="about-proekt">
      <div className="container">
        <div className="about-proekt__wrapper">
          <div className="about-proekt__box">
            <h2 className="about-proekt__title">
              {t("main.proektinner.oporket")}
            </h2>
            <p className="about-proekt__text">{data.description}</p>
            <button
              onClick={() => {
                downloadUrl(data?.file, data?.file_name);
              }}
              className="about-proekt__btn"
            >
              {t("main.proektinner.skachat")}
            </button>
          </div>
          <div className="about-proekts__cards">
            <div className="about-proekt__innerproekt">
              <h5 className="about-proekt__inner-title">
                {t("main.proektinner.height")}
              </h5>
              <h3 className="about-proekt__inner-abouttext">
                {data?.height} {t("main.proektinner.metr")}
              </h3>
            </div>
            <div className="about-proekt__innerlist">
              <div className="about-proekt__innerproekt">
                <h5 className="about-proekt__inner-title">
                  {t("main.proektinner.klass")}
                </h5>
                <h3 className="about-proekt__inner-abouttext">
                  {data?.class_of_complex}
                </h3>
              </div>
              <div className="about-proekt__innerproekt">
                <h5 className="about-proekt__inner-title">
                  {t("main.proektinner.etaj")}
                </h5>
                <h3 className="about-proekt__inner-abouttext">
                  {data?.etajnost}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Aboutended;
