import axios from "axios";
const downloadBlob = (blobObj, name) => {
  const anchorElement = document.createElement("a");
  const url = window.URL.createObjectURL(blobObj);
  anchorElement.href = url;
  anchorElement.download = name;
  document.body.appendChild(anchorElement);
  anchorElement.click();
  window.URL.revokeObjectURL(url);
  anchorElement.remove();
};
const downloadUrl = (href, name) => {
  return axios
    .get(href, {
      responseType: "blob",
    })
    .then((res) => {
      downloadBlob(res.data, name);
    })
    .catch((err) => {
      console.log(err);
    });
};

export default downloadUrl;
