import React, { useEffect, useRef, useState } from "react";
import Caruselbutton from "../../Caruselbuttons/Caruselbutton";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./infra.css";
import { useTranslation } from "react-i18next";
import BitrixForm from "../../Bitrixfrom/Bitrixform";
import Btrixmodal from "../../Btrixmodal/Btrixmodal";
const Infrastruct = ({ data }) => {
  const [modal, setModal] = useState(false);
  const { t } = useTranslation();
  const swiperRef = useRef(null);
  const swiperRef2 = useRef(null);
  const [loop, setLoop] = useState(false);
  const prevHandler = () => {
    swiperRef.current.slidePrev();
    setLoop(true);
  };

  const nextHandler = () => {
    swiperRef.current.slideNext();
    setLoop(true);
  };
  useEffect(() => {
    setTimeout(() => {
      setLoop(true);
    }, 200);
  }, []);

  return (
    <section className="infrasturct">
      <Btrixmodal modal={modal} setModal={setModal} />
      <div className="container">
        <div className="shortscarusel__wrapper">
          <h2 className="infrasturct__title">{t("main.proektinner.infra")}</h2>
          <div className="infrastruct__controler">
            <Caruselbutton
              handleClick={prevHandler}
              styleadd={"caruselback"}
              type={"prev"}
            />
            <Caruselbutton handleClick={nextHandler} styleadd={"caruselback"} />
          </div>
        </div>
        <div className="infrastruct__wrapper">
          <div className="infrastruct__box">
            <ul className="infrastruct__list">
              {data?.infrastructure?.map((item, index) => (
                <li key={index}>
                  <h5 className="infrastruct__list-title">{item?.key}</h5>
                  <p className="infrastruct__list-text">{item?.value}</p>
                </li>
              ))}
            </ul>
            <a onClick={() => setModal(true)} className="infrastruct__btn">
              {t("main.proektinner.call")}
            </a>
          </div>
          <div className="infrastruct__controler2">
            <Caruselbutton
              handleClick={prevHandler}
              styleadd={"caruselback"}
              type={"prev"}
            />
            <Caruselbutton handleClick={nextHandler} styleadd={"caruselback"} />
          </div>
          <div className="infrastruct__caruselbox">
            <Swiper
              loop={loop}
              spaceBetween={20}
              className="infrastruct__carusel"
              onSwiper={(swiper) => (swiperRef.current = swiper)}
            >
              {data?.infrastructura_images?.map((item, index) => (
                <SwiperSlide key={index} className="infrastruct__slide">
                  <img
                    src={item?.image}
                    alt="caruselaback"
                    className="infrastruct__caruselimg"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <a onClick={() => setModal(true)} className="infrastruct__btn2">
              {t("main.proektinner.call")}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Infrastruct;
