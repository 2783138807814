import EndedInnerproekt from "../pages/EndedProekt/Endedproekt";
import { Peredacha } from "../pages/Peredacha/Peredacha";
import { lazy, Suspense } from "react";
import Load from "../components/Load/Load";
const Home = lazy(() => import("../pages/Home/Home"));
const About = lazy(() => import("../pages/About/About"));
const Contact = lazy(() => import("../pages/Contact/Contact"));
const Kommersiya = lazy(() => import("../pages/Kommersiya/Kommersiya"));
const Blog = lazy(() => import("../pages/Blog/Blog"));
const InnerBlog = lazy(() => import("../pages/InnerBlog/InnerBlog"));
const InnerProduct = lazy(() => import("../pages/InnerProduct/InnerProduct"));
const Proekti = lazy(() => import("../pages/Proekti/Proekti"));
// const EndedInnerproekti = lazy(() => import('../pages/EndedProekt/Endedproekt'))

export const ROUTES = [
  {
    path: "/",
    component: (
      <Suspense fallback={<Load />}>
        <Home />
      </Suspense>
    ),
  },
  {
    path: "/about",
    component: (
      <Suspense fallback={<Load />}>
        <About />
      </Suspense>
    ),
  },
  {
    path: "/commerce",
    component: (
      <Suspense fallback={<Load />}>
        <Kommersiya />
      </Suspense>
    ),
  },
  {
    path: "/blog",
    component: (
      <Suspense fallback={<Load />}>
        <Blog />
      </Suspense>
    ),
  },
  {
    path: "/innerblog/:id",
    component: (
      <Suspense fallback={<Load />}>
        <InnerBlog />
      </Suspense>
    ),
  },
  {
    path: "/product",
    component: (
      <Suspense fallback={<Load />}>
        <Proekti />
      </Suspense>
    ),
  },
  {
    path: "/contact",
    component: (
      <Suspense fallback={<Load />}>
        <Contact />
      </Suspense>
    ),
  },
  {
    path: "/productitem/:id",
    component: (
      <Suspense fallback={<Load />}>
        <InnerProduct />
      </Suspense>
    ),
  },
  {
    path: "/endedproeckt/:id",
    component: <EndedInnerproekt />,
  },
  {
    path: "/peredacha",
    component: <Peredacha />,
  },
];

export default ROUTES;
