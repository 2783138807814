import axios from "axios";

const http = axios.create({
  baseURL: process.env.REACT_APP_API,
});
http.interceptors.request.use(
  (config) => {
    config.headers[
      "Authorization"
    ] = `Token 6cc5fe81a0dbdcbeddac4600e817a7ee8eea44cf`;
    //  config.headers["Authorization"] = `Bearer python3030`;
    //  config.headers["Authorization"] = `Basic python3030`;
    //  config.headers["apiKey"] = "python3030"
    //   config.headers["Accept"] = "application/json";
    // 'resolve.fallback: { "http": require.resolve("stream-http") }'
    // resolve.fallback:{ "http": false }
    //   'resolve.fallback: { "http": require.resolve("stream-http") }'
    // resolve.fallback: { "http": false }

    // config.headers["Access-Control-Allow-Origin"] = "*";
    // config.headers["Content-Type"] = "multipart/form-data";
    // config.headers['Content-Type'] = "application/json"
    return config;
  },
  (error) => Promise.reject(error)
);

export default http;
