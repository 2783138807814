import React, { useContext, useEffect, useState } from "react";
import "./callcard.css";
import http from "../../../untils/axios";
import { useTranslation } from "react-i18next";
import BitrixForm from "../../Bitrixfrom/Bitrixform";
import { Context } from "../../../context/Context";
const Callcard = () => {
  const { cityname } = useContext(Context);
  const { t, i18n } = useTranslation();
  const [image, setImage] = useState({});

  const getData = () => {
    http
      .get(
        `/${
          i18n.resolvedLanguage === "rus" ? "ru" : "kk"
        }/api/v1/contact/?city=${cityname}`
      )
      .then((res) => {
        setImage(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getData();
  }, [cityname]);

  return (
    <section className="callcard">
      <span id="callcard" className="callcard__smothed"></span>
      <div className="container">
        <div className="callcard__wrapper">
          <div className="callcard__form">
            <h2 className="callcard__form-title">
              {t("main.callcard.vapros")}
            </h2>
            <p className="callcard__form-text">{t("main.callcard.text")}</p>
            <div>
              <BitrixForm />
            </div>
          </div>
          <div className="callcard__image">
            <img className="callcard__image-image" src={image?.image} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Callcard;
