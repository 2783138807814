import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

const Context = createContext();

const ContextProvider = ({ children }) => {
  const { t, i18n } = useTranslation();

  const [cityname, setCityname] = useState(
    `${
      i18n.resolvedLanguage === "rus"
        ? sessionStorage.getItem("cityru")
        : sessionStorage.getItem("citykk")
    }` || ""
  );
  return (
    <Context.Provider value={{ cityname, setCityname }}>
      {children}
    </Context.Provider>
  );
};

export { Context, ContextProvider };
