import React from "react";
import "./ended.css";
import { useTranslation } from "react-i18next";
import CustomVideoPlayer from "../../Costumvideo/Costumvideo";
const Endedvideo = ({ video, videolink }) => {
  const { t } = useTranslation();
  return (
    <section className="ended-section">
      <div className="container">
        <h2 className="ended-section__title">{t("main.proektinner.obzor")}</h2>
        <div className="ended-section__wrapper">
          {video !== null ? (
            <CustomVideoPlayer url={video} />
          ) : (
            <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${videolink}`}
              frameborder="0"
              allowfullscreen
            ></iframe>
          )}
        </div>
      </div>
    </section>
  );
};

export default Endedvideo;
