import React, { useRef, useState } from "react";
import prevss from "../../../assets/Images/svg/prevss.svg";
import nextss from "../../../assets/Images/svg/nextss.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useTranslation } from "react-i18next";
const Drobdowncarusel = ({ images, text, setModal }) => {
  const swiperRef = useRef(null);
  const { t } = useTranslation();
  const [prevs, setPrevs] = useState(true);
  const [nexts, setNexts] = useState(true);
  const prevHandler = () => {
    swiperRef.current.slidePrev();
  };
  const nextHandler = () => {
    swiperRef.current.slideNext();
  };
  const handleBeforeChange = (swiper) => {
    const isNextSlideExist = swiper.activeIndex < swiper.slides.length - 1;
    const isPrevSlideExist = swiper.activeIndex > 0;
  };
  return (
    <div>
      <div className="dropdown__menu-box">
        <div className="drobdown__menuitem">
          <p className="drobdown__text">{text}</p>
          <a onClick={() => setModal(true)} className="drobdown__btn">
            {t("main.proektinner.call")}
          </a>
        </div>
        <div className="drobdown__controler">
          <button disabled={!prevs} onClick={prevHandler} className="prevss">
            <img src={prevss} alt="prev" />
          </button>
          <button disabled={!nexts} onClick={nextHandler} className="prevss">
            <img src={nextss} alt="next" />
          </button>
        </div>
      </div>
      <Swiper
        className="drobdown__swiper"
        loop
        spaceBetween={30}
        breakpoints={{ 680: { slidesPerView: 2 }, 1050: { slidesPerView: 3 } }}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onSlideChange={(swiper) => handleBeforeChange(swiper)}
      >
        {images?.map((item, index) => (
          <SwiperSlide className="drobdown__item" key={index}>
            <img src={item?.image} alt="img" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Drobdowncarusel;
