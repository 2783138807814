import React, { useContext, useEffect, useState } from "react";
import HeaderCarusel from "../../components/Home/HeroCarusel/HeroCarusel";
import InnerProektCarusel from "../../components/InnerProekt/InnerProektCarusel/InnerProektCarusel";
import AboutItem from "../../components/InnerProekt/Aboutitem/AboutItem";
import Infrastruct from "../../components/InnerProekt/Infrastruct/Infrastruct";
import Drobdown from "../../components/InnerProekt/Drobdown/Drobdown";
import Endedvideo from "../../components/EndedIner/Endedvideo/Endedvideo";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import http from "../../untils/axios";
import Aboutended from "../../components/EndedIner/Aboutended/Aboutended";
import Callcard from "../../components/Home/Callcard/Callcard";
import Planirvokaprduct from "../../components/Planirovka/Planirovkaproduct";
const EndedInnerproekt = () => {
  const { i18n } = useTranslation();
  const { id } = useParams();
  const [data, setData] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  const getData = () => {
    http
      .get(
        `${
          i18n.resolvedLanguage === "rus" ? "ru" : "kk"
        }/api/v1/finished-project/${id}/`
      )
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, [id]);
  return (
    <div>
      <HeaderCarusel barners={data?.banners} />
      <Aboutended data={data} />
      <InnerProektCarusel data={data?.advantages} />
      <Infrastruct data={data} />
      <Drobdown data={data} />
      <Planirvokaprduct code={data.planirovka} />
      <Endedvideo video={data?.review_video} videolink={data?.get_video_id} />
      {data?.finished_parking_description && (
        <AboutItem data={data} type={false} />
      )}
      <Callcard />
    </div>
  );
};

export default EndedInnerproekt;
