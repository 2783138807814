import React, { useRef, useState } from "react";
import Caruselbutton from "../../Caruselbuttons/Caruselbutton";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./style.css";
import { useTranslation } from "react-i18next";
const InnerProektCarusel = ({ data }) => {
  const { t } = useTranslation();
  const [prevs, setPrevs] = useState(true);
  const [nexts, setNexts] = useState(true);
  const swiperRef = useRef(null);
  const prevHandler = () => {
    swiperRef.current.slidePrev();
  };
  const nextHandler = () => {
    swiperRef.current.slideNext();
  };
  const handleBeforeChange = (swiper) => {
    const isNextSlideExist = swiper.activeIndex < swiper.slides.length - 1;
    const isPrevSlideExist = swiper.activeIndex > 0;
  };
  return (
    <section className="innerproekt-carusel">
      <div className="container">
        <div className="innerproekt-carusel__wrapper">
          <div className="innerproekt-carusel__title">
            {t("main.proektinner.prishest")}
          </div>
          <div className="kamvideos__controler">
            <Caruselbutton
              handleClick={prevHandler}
              styleadd={"caruselback"}
              active={prevs}
              type={"prev"}
            />
            <Caruselbutton
              active={nexts}
              handleClick={nextHandler}
              styleadd={"caruselback"}
            />
          </div>
        </div>
        <Swiper
          loop
          className="kamvideos__swiper"
          spaceBetween={30}
          breakpoints={{
            680: { slidesPerView: 2 },
            1050: { slidesPerView: 3 },
          }}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          onSlideChange={(swiper) => handleBeforeChange(swiper)}
        >
          {data?.map((item, index) => (
            <SwiperSlide key={index} className="kamvideos__item2">
              <div className="innerproektcarusel__items">
                <img
                  src={item?.image}
                  alt=""
                  className="proektitem__causel-img"
                />
                <div className="proektitemcarusel__text">
                  <h4 className="proektitemcarusel__title">{item?.title}</h4>
                  <p className="proektitemcarusel__info">{item?.description}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default InnerProektCarusel;
