import React from "react";
import "./aboutitem.css";
import { useTranslation } from "react-i18next";
const AboutItem = ({ data, type }) => {
  const { t } = useTranslation();
  return (
    <section className="aboutitem">
      <div className="container">
        <div className="aboutitem__wrapper">
          <div className="aboutitem__box">
            <h2 className="aboutitem__title">
              {t("main.proektinner.oparking")}
            </h2>
            {type ? (
              <p
                dangerouslySetInnerHTML={{ __html: data?.parking_description }}
                className="aboutitem__text"
              />
            ) : (
              <p
                dangerouslySetInnerHTML={{
                  __html: data?.finished_parking_description,
                }}
                className="aboutitem__text"
              />
            )}
            <div className="aboutitem__imgbox2">
              <img className="aboutitem__img" src={data?.image} alt="" />
            </div>
            <div className="aboutitem__innerbox">
              {data?.security_description}
            </div>
            <a href="#callcard" className="aboutitem__btn">
              {t("main.proektinner.btn")}
            </a>
          </div>
          <div className="aboutitem__imgbox">
            {type ? (
              <img
                className="aboutitem__img"
                src={data?.parking_image}
                alt=""
              />
            ) : (
              <img
                className="aboutitem__img"
                src={data?.finished_parking_image}
                alt=""
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutItem;
