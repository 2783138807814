import React from "react";
import "./style.css";
export const Peredacha = () => {
  return (
    <section className="peredacha__section">
      <div className="container">
        <h2 className="peredacha__title">
          Записаться на оформление документов по передаче
        </h2>
        <iframe
          class="frame_MDS"
          src="https://apps.masnaget.digital/galamat/"
          width="100%"
          height="100vh"
        ></iframe>
      </div>
    </section>
  );
};
