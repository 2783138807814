import React, { useEffect, useRef, useState } from "react";
import "./hero.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/swiper-bundle.css";
import prev from "../../../assets/Images/svg/prev.svg";
import next from "../../../assets/Images/svg/next.svg";
import { EffectFade, Autoplay } from "swiper/modules";

const HeroCarusel = ({ barners }) => {
  const swiperRef = useRef(null);
  const [loop, setLoop] = useState(false);

  const prevHandler = () => {
    swiperRef.current.slidePrev();
    setLoop(true);
  };
  const nextHandler = () => {
    swiperRef.current.slideNext();
    setLoop(true);
  };
  useEffect(() => {
    setTimeout(() => {
      setLoop(true);
    }, 200);
  }, []);

  return (
    <>
      <div className="herocarusel">
        <Swiper
          effect={"fade"}
          modules={[EffectFade, Autoplay]}
          className="herocarusel__swiper"
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
          loop={loop}
        >
          {barners?.map((item, index) => (
            <SwiperSlide key={index}>
              <img className="herocarusel-img" src={item?.image} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="herocarusel__controls">
          <div className="container">
            <div className="herocrusel__controls-wrapp">
              <button className="herocarusel__prev" onClick={prevHandler}>
                <img src={prev} alt="prev" />
              </button>
              <button className="herocarusel__next" onClick={nextHandler}>
                <img src={next} alt="next" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <section className="herocarusel__mobile">
        <div className="container">
          <Swiper className="herocarusel__swiper" spaceBetween={"30"}>
            {barners?.map((item, index) => (
              <SwiperSlide key={index} className="herocarusel__swiper-slide">
                <img
                  className="herocarusel-img"
                  src={item?.mobile_image}
                  alt=""
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default HeroCarusel;
