import imgload from "../../assets/Images/svg/loading.svg";
import "./laod.css";
const Load = () => {
  return (
    <div className="load">
      <img width={80} src={imgload} alt="imgload" />
    </div>
  );
};

export default Load;
