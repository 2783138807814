import React, { useState } from "react";
import "./modal.css";
import closeimg from "../../assets/Images/svg/Close.svg";
const Btrixmodal = ({ modal, setModal }) => {
  const handleYonmodal = (e) => {
    if (e.target.id === "modalbtrix") {
      setModal(false);
    }
  };
  return (
    <div>
      <div
        onClick={(e) => handleYonmodal(e)}
        id="modalbtrix"
        className={modal ? "btirixmodal btirixmodal__active " : " btirixmodal"}
      >
        <div className="btirixmodal__main__inner">
          <button onClick={() => setModal(false)} className="btrix__closebtn">
            <img width={18} src={closeimg} alt="" />
          </button>
          <div
            className="btirixmodal__inner"
            dangerouslySetInnerHTML={{
              __html: `<script data-b24-form="inline/5/lctov3" data-skip-moving="true">
          (function(w,d,u){
              var s=d.createElement('script');
              s.async=true;
              s.src=u+'?'+(Date.now()/180000|0);
              var h=d.getElementsByTagName('script')[0];
              h.parentNode.insertBefore(s,h);
          })(window,document,'https://bitrix.galamat.kz/upload/crm/form/loader_5_lctov3.js');
      </script>`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Btrixmodal;
